import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player/lazy"

export function GalleryGrid() {
  return (
    <div className="gallery-wrapper">
      <StaticImage
        src="../../static/1.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/2.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/3.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/4.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/5.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/6.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/7.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/8.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/9.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/10.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/11.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/12.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/13.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/14.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/15.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/16.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/17.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/18.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/19.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/20.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/21.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/22.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
      <StaticImage
        src="../../static/23.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/24.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/25.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/26.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/27.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/28.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/29.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/30.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/31.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/32.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/33.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/34.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/35.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/36.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/37.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/38.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />

      <StaticImage
        src="../../static/39.png"
        alt="will sass"
        layout="constrained"
        objectFit="cover"
        placeholder="blurred"
        aspectRatio="1"
      />
    </div>
  )
}
