import React, { useState, useEffect } from "react"
import { Layout } from "../components/layout"
import { GalleryGrid } from "../components/galleryGrid"
import ReactPlayer from "react-player/lazy"

export default function Gallery() {
  const [showBio, setShowBio] = useState(false)

  useEffect(() => {
    let timer2 = setTimeout(() => setShowBio(true), 600)

    return () => {
      clearTimeout(timer2)
    }
  }, [])

  return (
    <Layout>
      <div className="bio-wrapper">
        {/* <div className="bio-blurb-wrapper">
          <div className="bio-blurb">
            <h1>
              WILL SASS IS AN ARTIST,<br></br>
              SONGWRITER AND PRODUCER <br></br>
              BORN AND RAISED IN NYC
            </h1>
          </div>
        </div> */}

        <GalleryGrid />
      </div>
    </Layout>
  )
}
